import { ICoordinates, useMapContext } from 'components/MapContextProvider';
import { SVGMotionProps, motion } from 'framer-motion';
import { THexSegment, generateHexSegmentPath } from 'lib/hex';
import * as React from 'react';

interface IProps {
  coordinates: ICoordinates;
  segmentKey: THexSegment;
  active?: boolean;
}

const HexSegment: React.FC<IProps & SVGMotionProps<SVGPathElement>> = ({
  coordinates,
  segmentKey,
  active,
  ...rest
}) => {
  const { layout } = useMapContext();
  const path = generateHexSegmentPath(coordinates, layout.hexRadius * 0.9, segmentKey, 0.75);

  return (
    <motion.path
      style={{ pointerEvents: 'none' }}
      variants={{
        active: { fillOpacity: 1 },
        inactive: { fillOpacity: 0 },
      }}
      initial={'inactive'}
      animate={active ? 'active' : 'inactive'}
      d={path}
      fill="white"
      {...rest}
    />
  );
};

export default HexSegment;
