import {
  BorderProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  SpaceProps,
  TypographyProps,
  border,
  color,
  flexbox,
  layout,
  space,
  typography,
} from '@techstack/styled-system';
import styled from 'styled-components';

export const Box = styled.div<SpaceProps & LayoutProps & ColorProps & BorderProps & TypographyProps>`
  box-sizing: border-box;
  min-width: 0;

  ${space}
  ${layout}
  ${color}
  ${border}
  ${typography}
`;

export const Flex = styled(Box)<FlexboxProps>`
  display: flex;

  ${flexbox}
`;

export const Column = styled(Flex)`
  flex-direction: column;
`;

export const Text = styled.p<SpaceProps & LayoutProps & ColorProps & TypographyProps>`
  ${typography}
  ${space}
  ${layout}
  ${color}
`;

export const Dot = styled.span<ColorProps>`
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  border-radius: 100%;

  ${color};
`;
