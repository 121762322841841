import { ICoordinates, useMapContext } from 'components/MapContextProvider';
import { SVGMotionProps, motion } from 'framer-motion';
import { generateHexPath } from 'lib/hex';
import * as React from 'react';

interface IProps {
  coordinates: ICoordinates;
  progress: number;
}

const HexProgress: React.FC<IProps & SVGMotionProps<SVGPathElement>> = ({ coordinates, progress, ...rest }) => {
  const { layout } = useMapContext();
  const path = generateHexPath(coordinates, layout.hexRadius * 0.9, 'top');

  return (
    <motion.path
      stroke="orange"
      fill="transparent"
      strokeWidth={6}
      animate={{ pathLength: progress }}
      transition={{ duration: 1, ease: 'linear' }}
      d={path}
      style={{ pointerEvents: 'none' }}
      {...rest}
    />
  );
};

export default HexProgress;
