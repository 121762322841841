import { useMapContext } from 'components/MapContextProvider';
import { Box, Column, Dot, Text } from 'components/atoms/Layout';
import {
  IItem,
  ILocation,
  IPlayer,
  IUnit,
  TMarker,
  isCircleMarker,
  isPlayerMarker,
  isProgressMarker,
  isSegmentMarker,
} from 'lib/api';
import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  location?: ILocation;
  players?: IPlayer[];
  markers?: TMarker[];
  items?: IItem[];
  units?: IUnit[];
}

const LocationDetail: React.FC<IProps> = ({ location, players, markers, items, units }) => {
  const { teams } = useMapContext();
  const team = teams[location?.team_id ?? ''];

  const locationPlayers = players?.filter(p => p.placement_id === location?.id) ?? [];
  const locationItems = items?.filter(i => i.placement_id === location?.id) ?? [];
  const locationMarkers =
    markers?.filter(
      m =>
        m.placement_id === location?.id &&
        !isPlayerMarker(m) &&
        (!isCircleMarker(m) || (m.label != null && m.label.trim() != ''))
    ) ?? [];
  const locationUnits = units?.filter(u => u.placement_id === location?.id) ?? [];

  return (
    <LocationDetailContainer>
      {location == null && <h2>Select location</h2>}

      {location != null && (
        <>
          <Box backgroundColor={team?.color ?? 'locationBackground'} p={2}>
            <Text color="locationText" textAlign="center">
              {location.name}
            </Text>
          </Box>
          <Column p={2} fontSize={14}>
            {locationPlayers.length > 0 && (
              <>
                <Text>Players:</Text>
                <ul className="pl-2">
                  {locationPlayers.length > 0 ? (
                    locationPlayers.map(player => (
                      <li key={player.id} className="list-none">
                        <div className="flex items-center gap-2">
                          <img src={player.avatar_url} alt={player.name} className="w-6 h-6" />
                          <Text color={teams[player.team_id ?? '']?.color ?? 'unknownTeamColor'}>{player.name}</Text>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>
                      <Text color="mutedText">No players</Text>
                    </li>
                  )}
                </ul>
              </>
            )}
            {locationItems.length > 0 && (
              <>
                <Text mt={2}>Items:</Text>
                <ul className="pl-8 list-disc">
                  {locationItems.length > 0 ? (
                    locationItems.map(item => <li key={item.id}>{item.name}</li>)
                  ) : (
                    <li>
                      <Text color="mutedText">No items</Text>
                    </li>
                  )}
                </ul>
              </>
            )}
            {locationUnits.length > 0 && (
              <>
                <Text mt={2}>Units:</Text>
                <ul className="pl-8 list-disc">
                  {locationUnits.length > 0 ? (
                    locationUnits.map(unit => (
                      <li key={unit.id}>
                        <Dot backgroundColor={teams[unit.team_id ?? '']?.color ?? 'locationBackground'} />
                        &nbsp; {unit.callsign} - {unit.name} ({unit.quantity})
                      </li>
                    ))
                  ) : (
                    <li>
                      <Text color="mutedText">No units</Text>
                    </li>
                  )}
                </ul>
              </>
            )}
            {locationMarkers.length > 0 && (
              <>
                <Text mt={2}>Markers:</Text>
                <ul className="pl-8 list-disc">
                  {locationMarkers.length > 0 ? (
                    locationMarkers.map(marker => (
                      <li key={marker.id}>
                        {isCircleMarker(marker) ? (
                          <>
                            <Dot backgroundColor={marker.marker_props?.fill} />
                            &nbsp; {marker.label}
                          </>
                        ) : isSegmentMarker(marker) ? (
                          <>
                            <Dot backgroundColor={marker.color} />
                            &nbsp; {marker.label}
                          </>
                        ) : isProgressMarker(marker) ? (
                          <>
                            <Dot backgroundColor={marker.color} />
                            &nbsp; Progress: {marker.progress}
                          </>
                        ) : (
                          <>Unknown marker type</>
                        )}
                      </li>
                    ))
                  ) : (
                    <li>
                      <Text color="mutedText">No markers</Text>
                    </li>
                  )}
                </ul>
              </>
            )}
          </Column>
        </>
      )}
    </LocationDetailContainer>
  );
};

const LocationDetailContainer = styled(Column)`
  position: absolute;
  top: 0;
  left: 0;

  min-width: 250px;
  max-height: 50%;

  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.locationBackground};
  border-width: 0 1px 1px 0;
  border-radius: 0 0 5px 0;
  overflow: hidden;
  overflow-y: auto;
`;

export default LocationDetail;
