import { useCallback, useEffect, useRef } from 'react';

const ZOOM_SENSITIVITY = 0.8;

const usePanAndZoom = () => {
  const positionRef = useRef({ x: 0, y: 0 });
  const zoomRef = useRef(1);

  const targetRef = useRef<SVGSVGElement | null>(null);

  const updateTransform = useCallback(() => {
    if (targetRef.current == null) return;

    targetRef.current.style.transform = `translate(${positionRef.current.x}px, ${positionRef.current.y}px) scale(${zoomRef.current})`;
  }, []);

  // Zooming
  const zoom = useCallback((factor: number, point: { x: number; y: number }) => {
    zoomRef.current *= factor;
    positionRef.current.x -= (point.x - positionRef.current.x) * (factor - 1);
    positionRef.current.y -= (point.y - positionRef.current.y) * (factor - 1);
    updateTransform();
  }, []);

  const handleScroll = useCallback((e: WheelEvent) => {
    const mapSvg = targetRef.current?.parentElement;
    if (mapSvg == null || e.target == null || !(e.target instanceof Node) || !mapSvg.contains(e.target)) return;

    const factor = e.deltaY < 0 ? 1 / ZOOM_SENSITIVITY : ZOOM_SENSITIVITY;
    zoom(factor, { x: e.offsetX, y: e.offsetY });
  }, []);

  const handleDoubleClick = useCallback((e: MouseEvent) => {
    const mapSvg = targetRef.current?.parentElement;
    if (mapSvg == null || e.target == null || !(e.target instanceof Node) || !mapSvg.contains(e.target)) return;

    zoom(1 / ZOOM_SENSITIVITY, { x: e.clientX, y: e.clientY });
  }, []);

  // Panning
  const handleMouseDown = useCallback((e: MouseEvent) => {
    if (e.button !== 0) return;

    const mapSvg = targetRef.current?.parentElement;
    if (mapSvg == null || e.target == null || !(e.target instanceof Node) || !mapSvg.contains(e.target)) return;

    let startX = e.clientX;
    let startY = e.clientY;

    const handleMouseMove = (e: MouseEvent) => {
      positionRef.current.x += e.clientX - startX;
      positionRef.current.y += e.clientY - startY;

      startX = e.clientX;
      startY = e.clientY;

      updateTransform();
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }, []);

  // Attach event listeners
  useEffect(() => {
    document.addEventListener('wheel', handleScroll, { passive: true });
    document.addEventListener('mousedown', handleMouseDown, { passive: true });
    document.addEventListener('dblclick', handleDoubleClick, { passive: true });

    return () => {
      document.removeEventListener('wheel', handleScroll);
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('dblclick', handleDoubleClick);
    };
  }, []);

  const zoomToFit = useCallback((options?: { padding: number }) => {
    const padding = options?.padding ?? 0;

    const mainG = targetRef.current;
    if (mainG == null) return;

    const svg = mainG.ownerSVGElement;
    if (svg == null) return;

    const { width, height, x, y } = mainG.getBBox();
    const { clientWidth, clientHeight } = svg;

    const scaleX = clientWidth / (width + padding * 2);
    const scaleY = clientHeight / (height + padding * 2);

    const factor = Math.min(scaleX, scaleY);
    zoomRef.current = factor;

    positionRef.current.x = (padding - x) * factor;
    positionRef.current.y = (padding - y) * factor;

    // Center the content
    if (scaleX < scaleY) {
      positionRef.current.y += (clientHeight - (height + padding * 2) * factor) / 2;
    } else {
      positionRef.current.x += (clientWidth - (width + padding * 2) * factor) / 2;
    }

    updateTransform();
  }, []);

  return { targetRef, zoomToFit };
};

export default usePanAndZoom;
