import { ICoordinates } from 'components/MapContextProvider';
import { SVGMotionProps, motion } from 'framer-motion';
import { IPlayerMarker } from 'lib/api';
import * as React from 'react';

interface IProps {
  coordinates: ICoordinates;
  marker: IPlayerMarker;
}

const PlayerMarker: React.FC<IProps & SVGMotionProps<SVGImageElement>> = ({ marker, coordinates, ...rest }) => {
  const { id, avatar_url } = marker;
  const { locX, locY } = coordinates;
  const size = 20;
  const x = locX - size / 2;
  const y = locY - size / 2;

  return (
    <motion.image
      key={id}
      initial={{ x, y }}
      animate={{ x, y }}
      href={avatar_url}
      width={size}
      height={size}
      {...rest}
    />
  );
};

export default PlayerMarker;
