import { ICoordinates, IPosition } from 'components/MapContextProvider';

class HexLayout {
  hexRadius: number;

  constructor(hexRadius: number) {
    this.hexRadius = hexRadius;
  }

  getPositionCoordinates = (position: IPosition): ICoordinates => {
    // Calculate the center position of each hexagon
    const dx = this.hexRadius * Math.sqrt(3);

    const locX = dx * position.x + ((position.y % 2) * dx) / 2;
    const locY = this.hexRadius * position.y * 1.5;
    return { locX, locY };
  };

  getCoordinatesForMarker = (
    position: IPosition,
    orbit: number = 0.25,
    totalCount: number = 1,
    index: number = 0
  ): ICoordinates => {
    const baseCoordinates = this.getPositionCoordinates(position);

    const angle = (2 * Math.PI) / totalCount;
    const radius = this.hexRadius * orbit;
    const rotation = Math.PI / 2 + index * angle;

    return {
      locX: baseCoordinates.locX + Math.cos(rotation) * radius,
      locY: baseCoordinates.locY + Math.sin(rotation) * radius,
    };
  };

  static calculateHexRadius = (width: number, height: number, gridSize: number) => {
    // The maximum radius the hexagons can have to still fit the screen
    const wRadius = width / ((gridSize + 0.5) * Math.sqrt(3));
    const hRadius = height / ((gridSize + 1 / 3) * 1.5);
    return Math.floor(Math.min(wRadius, hRadius));
  };
}

export default HexLayout;
