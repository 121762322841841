import { ICoordinates, useMapContext } from 'components/MapContextProvider';
import Route from 'components/atoms/Route';
import { ILocation, IRoute } from 'lib/api';
import * as React from 'react';
import { useTheme } from 'styled-components';

interface IProps {
  routes: IRoute[];
  locations: ILocation[];
}

const Routes: React.FC<IProps> = React.memo(({ routes, locations }) => {
  const { colors } = useTheme();
  const { layout } = useMapContext();

  const coordinatesLookup = React.useMemo(() => {
    return locations.reduce<{ [key: string]: ICoordinates }>((acc, location) => {
      acc[location.id] = layout.getPositionCoordinates({ x: location.x ?? 0, y: location.y ?? 0 });
      return acc;
    }, {});
  }, [locations, layout.getPositionCoordinates]);

  const getLocationCoordinates = (locationId: string) => coordinatesLookup[locationId] ?? { locX: 0, locY: 0 };

  return (
    <>
      {routes.map(route => (
        <Route
          key={route.id}
          fromCoordinates={getLocationCoordinates(route.location1_id)}
          toCoordinates={getLocationCoordinates(route.location2_id)}
          stroke={route['player_traversable?'] ? colors.routeBackground : colors.routeBackgroundBlocked}
          strokeOpacity={0.8}
          strokeWidth={20}
        />
      ))}
    </>
  );
});

export default Routes;
