import { FetchRequest } from '@rails/request.js';
import { THexSegment } from './hex';

export const fetchMapData = async (dataSourceUrl: string): Promise<MapData> => {
  const request = new FetchRequest('get', dataSourceUrl);
  const response = await request.perform();

  if (response.ok) {
    return (await response.json) as MapData;
  } else throw new Error('Failed to fetch data');
};

export interface MapData {
  teams: ITeam[];
  locations: ILocation[];
  routes: IRoute[];
  players: IPlayer[];
  markers: TMarker[];
  items: IItem[];
  units: IUnit[];
}

export interface IUnit {
  __typename: 'Unit';
  id: string;
  name: string;
  placement_id: string | null;
  quantity: number;
  team_id: string | null;
  callsign: string;
  health: number | null;
}

export interface ITeam {
  __typename: 'Team';
  id: string;
  key: string;
  name: string;
  color: string;
  readable_resources: [string, number][];
  research_points: number;
  hidden: boolean;
}

export interface ILocation {
  __typename: 'BaseLocation' | 'BattleLocation';
  id: string;
  name: string;
  team_id: string;
  type: string;
  is_default: boolean;
  x: number | null;
  y: number | null;
  obscured?: boolean;
}

export interface IRoute {
  __typename: 'Route';
  id: string;
  location1_id: string;
  location2_id: string;
  distance: number;
  'player_traversable?': boolean;
}

export interface IPlacable {
  placement_id: string;
}

export interface IPlayer extends IPlacable {
  __typename: 'Player';
  id: string;
  name: string;
  team_id: string | null;
  avatar_url: string;
}

export interface IItem extends IPlacable {
  __typename: 'Item';
  id: string;
  name: string;
}

export interface IMarker extends IPlacable {
  id: string;
}

export interface ICircleMarker extends IMarker {
  __typename: 'CircleMarker';
  label: string;
  marker_props?: {
    r?: number;
    fill?: string;
    stroke?: string;
    strokeWidth?: number;
    orbit?: number;
  };
  text_props?: {
    fill?: string;
    fontSize?: number;
    fontWeight?: string | number;
  };
}

export interface IPlayerMarker extends IMarker {
  __typename: 'PlayerMarker';
  avatar_url: string;
  name: string;
  team_id: string | null;
}

export interface IProgressMarker extends IMarker {
  __typename: 'ProgressMarker';
  progress: number;
  color: string;
}

export interface ISegmentMarker extends IMarker {
  __typename: 'SegmentMarker';
  segment: THexSegment;
  color: string;
  label: string;
}

export type TMarker = ICircleMarker | IProgressMarker | ISegmentMarker | IPlayerMarker;

export const isCircleMarker = (marker: TMarker): marker is ICircleMarker => marker.__typename === 'CircleMarker';
export const isProgressMarker = (marker: TMarker): marker is IProgressMarker => marker.__typename === 'ProgressMarker';
export const isSegmentMarker = (marker: TMarker): marker is ISegmentMarker => marker.__typename === 'SegmentMarker';
export const isPlayerMarker = (marker: TMarker): marker is IPlayerMarker => marker.__typename === 'PlayerMarker';
