import { ICoordinates } from 'components/MapContextProvider';
import { SVGMotionProps, motion } from 'framer-motion';
import { ICircleMarker } from 'lib/api';
import * as React from 'react';

interface IProps {
  coordinates: ICoordinates;
  marker: ICircleMarker;
  teamColor?: string;
}

const CircleMarker: React.FC<IProps & SVGMotionProps<SVGCircleElement>> = ({ marker, coordinates }) => {
  const { id, label, marker_props, text_props } = marker;
  const { locX, locY } = coordinates;

  return (
    <>
      <motion.circle
        key={id}
        initial={{ x: locX, y: locY }}
        animate={{ x: locX, y: locY }}
        r={10}
        fill="#ffffff"
        stroke="#000000"
        strokeWidth={0}
        {...marker_props}
      />
      {label && label !== '' && (
        <motion.text
          key={`${marker.id}_text`}
          style={{ pointerEvents: 'none' }}
          textAnchor="middle"
          dominantBaseline="middle"
          initial={{ x: locX, y: locY }}
          animate={{ x: locX, y: locY }}
          fill="#000000"
          fontSize={16}
          {...text_props}
        >
          {marker.label}
        </motion.text>
      )}
    </>
  );
};

export default CircleMarker;
