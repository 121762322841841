import { Box, Column, Flex, Text } from 'components/atoms/Layout';
import { ITeam, IUnit } from 'lib/api';
import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  teams: ITeam[];
  units: IUnit[];
}

const AliensTracker: React.FC<IProps> = ({ teams, units }) => {
  const aliensTeam = teams.find(t => t.key === 'aliens');
  const aliens = aliensTeam ? units.filter(u => u.team_id === aliensTeam.id) : [];

  if (aliensTeam == null || aliens.length === 0) {
    return null;
  }

  return (
    <Flex justifyContent="center">
      <Container>
        <Box backgroundColor={aliensTeam.color ?? 'locationBackground'} p={2}></Box>
        <Column p={2} fontSize={14} alignItems="flex-start">
          {aliens.map(({ name, health }) => (
            <Text key={name}>
              {name}: <strong>{health}HP</strong>
            </Text>
          ))}
        </Column>
      </Container>
    </Flex>
  );
};

const Container = styled(Column)`
  position: absolute;
  top: 0;
  margin: 0 auto;

  min-width: 250px;
  max-height: 50%;

  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.locationBackground};
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
  overflow-y: auto;
`;

export default AliensTracker;
