import { ILocation } from 'lib/api';
import * as React from 'react';
import Location from './Location';

interface IProps {
  locations: ILocation[];
  activeLocationId: string | null;
  setActiveLocationId: (location: string | null) => void;
}

const Locations: React.FC<IProps> = React.memo(({ locations, activeLocationId, setActiveLocationId }) => {
  return (
    <>
      {locations.map(location => (
        <Location
          key={location.id}
          location={location}
          onClick={() => {
            setActiveLocationId(activeLocationId === location.id ? null : location.id);
          }}
          active={activeLocationId === location.id}
        />
      ))}
    </>
  );
});

export default Locations;
