import { ICoordinates } from 'components/MapContextProvider';
import * as React from 'react';

interface IProps {
  fromCoordinates: ICoordinates;
  toCoordinates: ICoordinates;
}

const Route: React.FC<IProps & React.SVGProps<SVGLineElement>> = ({ fromCoordinates, toCoordinates, ...rest }) => {
  const { locX: fromX, locY: fromY } = fromCoordinates;
  const { locX: toX, locY: toY } = toCoordinates;

  return <line x1={fromX} y1={fromY} x2={toX} y2={toY} {...rest} />;
};

export default Route;
