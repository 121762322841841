import { ITeam } from 'lib/api';
import HexLayout from 'lib/layout';
import * as React from 'react';

interface IMapContext {
  layout: HexLayout;
  teams: Record<string, ITeam>;
}

export interface ICoordinates {
  locX: number;
  locY: number;
}

export interface IPosition {
  x: number;
  y: number;
}

const MapContext = React.createContext<IMapContext>({
  layout: new HexLayout(100),
  teams: {},
});

export const useMapContext = () => React.useContext(MapContext);

interface IProps {
  hexRadius: number;
  teams: ITeam[];
  children?: React.ReactNode;
}

const MapContextProvider: React.FC<IProps> = ({ hexRadius, children, teams }) => {
  const layout = React.useMemo(() => new HexLayout(hexRadius), [hexRadius]);

  const teamsLookup = React.useMemo(() => {
    return teams.reduce<Record<string, ITeam>>((acc, team) => {
      acc[team.id] = team;
      return acc;
    }, {});
  }, [teams]);

  const ctx = React.useMemo(() => ({ layout, teams: teamsLookup }), [layout, teamsLookup]);

  return <MapContext.Provider value={ctx}>{children}</MapContext.Provider>;
};

export default MapContextProvider;
