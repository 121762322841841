import { Box, Column, Text } from 'components/atoms/Layout';
import { ITeam } from 'lib/api';
import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  team: ITeam;
  side: 'left' | 'right';
}

const TeamScore: React.FC<IProps> = ({ team, side }) => {
  return (
    <Wrapper side={side}>
      <Box backgroundColor={team.color ?? 'locationBackground'} p={2}></Box>
      <Column p={2} fontSize={14} alignItems={side === 'left' ? 'flex-start' : 'flex-end'}>
        {team.readable_resources
          .sort((a, b) => b[1] - a[1])
          .map(([name, quantity]) => (
            <Text key={name}>
              {name}: <strong>{quantity}</strong>
            </Text>
          ))}
      </Column>
    </Wrapper>
  );
};

const Wrapper = styled(Column)<{ side: 'left' | 'right' }>`
  position: absolute;
  ${({ side }) => side}: 0;
  bottom: 0;

  min-width: 250px;

  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.locationBackground};
  border-width: 1px 0 0 0;
  border-${({ side }) => (side === 'left' ? 'right' : 'left')}-width: 1px;
  border-radius: 0;
  border-top-${({ side }) => (side === 'left' ? 'right' : 'left')}-radius: 5px;
  overflow: hidden;

  ul {
    list-style: disc;
    padding-left: 30px;
  }
`;

export default TeamScore;
