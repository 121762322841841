import { ICoordinates, useMapContext } from 'components/MapContextProvider';
import { generateHexPath } from 'lib/hex';
import * as React from 'react';
import { SVGMotionProps, motion } from 'framer-motion';

interface IProps {
  coordinates: ICoordinates;
  label?: string;
  labelProps?: React.SVGProps<SVGTextElement>;
}

const Hex: React.FC<IProps & SVGMotionProps<SVGPathElement>> = ({ coordinates, label, labelProps, ...rest }) => {
  const { layout } = useMapContext();
  const path = generateHexPath(coordinates, layout.hexRadius * 0.95);

  return (
    <>
      <motion.path d={path} {...rest} />
      {label && (
        <text
          style={{ pointerEvents: 'none' }}
          x={coordinates.locX}
          y={coordinates.locY}
          textAnchor="middle"
          dominantBaseline="middle"
          {...labelProps}
        >
          {label}
        </text>
      )}
    </>
  );
};

export default Hex;
