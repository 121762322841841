import { useQuery } from '@tanstack/react-query';
import AliensTracker from 'components/organisms/AliensTracker';
import { motion } from 'framer-motion';
import usePanAndZoom from 'hooks/usePanAndZoom';
import { fetchMapData } from 'lib/api';
import React, { useEffect } from 'react';
import MapContextProvider from '../MapContextProvider';
import Button from '../atoms/Button';
import { Flex } from '../atoms/Layout';
import Locations from '../molecules/Locations';
import Markers from '../molecules/Markers';
import Routes from '../molecules/Routes';
import LocationDetail from '../organisms/LocationDetail';
import ScoreTracker from '../organisms/ScoreTracker';

interface IProps {
  dataSourceUrl: string;
}

const HEX_RADIUS = 100;

const MapPage: React.FC<IProps> = ({ dataSourceUrl }) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['mapData'],
    queryFn: () => fetchMapData(dataSourceUrl),
    refetchInterval: 1000,
  });

  const { targetRef, zoomToFit } = usePanAndZoom();
  const [activeLocationId, setActiveLocationId] = React.useState<string | null>(null);
  const activeLocation = data?.locations.find(l => l.id === activeLocationId);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => zoomToFit({ padding: 30 }), 100);
    }
  }, [isLoading]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data</div>;
  }

  const { routes, locations, markers, players, teams, items, units } = data;

  return (
    <MapContextProvider hexRadius={HEX_RADIUS} teams={teams}>
      <LocationDetail location={activeLocation} players={players} markers={markers} items={items} units={units} />
      <AliensTracker teams={teams} units={units} />
      <ScoreTracker teams={teams} />
      <Flex className="absolute top-0 right-0 gap-1 p-2">
        <Button onClick={() => zoomToFit({ padding: 30 })}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-4 h-4" fill="currentColor">
            <path d="M200 32H56C42.7 32 32 42.7 32 56V200c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l40-40 79 79-79 79L73 295c-6.9-6.9-17.2-8.9-26.2-5.2S32 302.3 32 312V456c0 13.3 10.7 24 24 24H200c9.7 0 18.5-5.8 22.2-14.8s1.7-19.3-5.2-26.2l-40-40 79-79 79 79-40 40c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H456c13.3 0 24-10.7 24-24V312c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2l-40 40-79-79 79-79 40 40c6.9 6.9 17.2 8.9 26.2 5.2s14.8-12.5 14.8-22.2V56c0-13.3-10.7-24-24-24H312c-9.7 0-18.5 5.8-22.2 14.8s-1.7 19.3 5.2 26.2l40 40-79 79-79-79 40-40c6.9-6.9 8.9-17.2 5.2-26.2S209.7 32 200 32z" />
          </svg>
        </Button>
      </Flex>
      <svg width="100%" height="100%">
        <motion.g layout id="main" ref={targetRef}>
          <ZeroMarker />
          <Routes routes={routes} locations={locations} />
          <Locations
            locations={locations}
            activeLocationId={activeLocationId}
            setActiveLocationId={setActiveLocationId}
          />
          <Markers markers={markers} locations={locations} />
        </motion.g>
      </svg>
    </MapContextProvider>
  );
};

const ZeroMarker: React.FC = () => (
  <>
    <line x1={-10} y1={0} x2={10} y2={0} stroke="black" strokeOpacity={0.8} strokeWidth={1} />
    <line x1={0} y1={-10} x2={0} y2={10} stroke="black" strokeOpacity={0.8} strokeWidth={1} />
    <text x={5} y={10} fill="black" fontSize={10} alignmentBaseline="central">
      0,0
    </text>
  </>
);

export default MapPage;
