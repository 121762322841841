import { ICoordinates } from 'components/MapContextProvider';

export type THexPoint = 'top' | 'topRight' | 'bottomRight' | 'bottom' | 'bottomLeft' | 'topLeft';
const POINT_ORDER: THexPoint[] = ['top', 'topRight', 'bottomRight', 'bottom', 'bottomLeft', 'topLeft'];
export type THexSegment = 'right' | 'topRight' | 'bottomRight' | 'left' | 'bottomLeft' | 'topLeft' | 'center';
const SEGMENT_ORDER: THexSegment[] = ['topRight', 'right', 'bottomRight', 'bottomLeft', 'left', 'topLeft'];

/**
 * Calculates the center of a hexagon segment.
 */
export const generateHexSegmentCenter = (
  hexCenter: ICoordinates,
  radius: number,
  segment: THexSegment,
  innerRadius = 0.5
): ICoordinates => {
  const hexSegmentPoints = getHexSegmentPoints(hexCenter, radius, segment, innerRadius);

  const locX = hexSegmentPoints.reduce((acc, point) => acc + point.locX, 0) / hexSegmentPoints.length;
  const locY = hexSegmentPoints.reduce((acc, point) => acc + point.locY, 0) / hexSegmentPoints.length;

  return { locX, locY };
};

/**
 * Generates the points of a hexagon, starting from given point, centered at given coordinates and with given radius.
 */
export const getHexPoints = (center: ICoordinates, radius: number, start: THexPoint = 'top'): ICoordinates[] => {
  const points = [];
  const offset = POINT_ORDER.indexOf(start);
  for (let i = 0; i < 6; i++) {
    const angle = (Math.PI / 3) * i - Math.PI / 2 + (Math.PI / 3) * offset;
    points.push({
      locX: center.locX + radius * Math.cos(angle),
      locY: center.locY + radius * Math.sin(angle),
    });
  }
  return points;
};

/**
 * Generates the path of a hexagon, starting from given point, centered at given coordinates and with given radius.
 */
export const generateHexPath = (center: ICoordinates, radius: number, start: THexPoint = 'top'): string => {
  const points = getHexPoints(center, radius, start);
  return points.map((point, i) => `${i === 0 ? 'M' : 'L'} ${point.locX} ${point.locY}`).join(' ') + ' Z';
};

/**
 * Generates the points of a hexagon segment, for hexagon described by given center and radius.
 */
export const getHexSegmentPoints = (
  hexCenter: ICoordinates,
  radius: number,
  segment: THexSegment,
  innerRadius = 0.5
): ICoordinates[] => {
  const absoluteInnerRadius = innerRadius < 1 ? radius * innerRadius : innerRadius;

  if (segment === 'center') {
    return getHexPoints(hexCenter, absoluteInnerRadius);
  }

  const outerPoints = getHexPoints(hexCenter, radius, 'top');
  const innerPoints = getHexPoints(hexCenter, absoluteInnerRadius, 'top');

  const start = SEGMENT_ORDER.indexOf(segment);
  const points = [
    outerPoints[start],
    outerPoints[(start + 1) % outerPoints.length],
    innerPoints[(start + 1) % outerPoints.length],
    innerPoints[start],
  ];

  return points;
};

/**
 * Generates the path of a hexagon segment, for hexagon described by given center and radius.
 */
export const generateHexSegmentPath = (
  hexCenter: ICoordinates,
  radius: number,
  segment: THexSegment,
  innerRadius = 0.5
): string => {
  const absoluteInnerRadius = innerRadius < 1 ? radius * innerRadius : innerRadius;

  if (segment === 'center') {
    return generateHexPath(hexCenter, absoluteInnerRadius);
  }

  const points = getHexSegmentPoints(hexCenter, radius, segment, innerRadius);
  return points.map((point, i) => `${i === 0 ? 'M' : 'L'} ${point.locX} ${point.locY}`).join(' ') + ' Z';
};
