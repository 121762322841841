import { useMapContext } from 'components/MapContextProvider';
import Hex from 'components/atoms/Hex';
import { ILocation } from 'lib/api';
import * as React from 'react';
import { useTheme } from 'styled-components';

interface IProps {
  location: ILocation;
  onClick?: () => void;
  active?: boolean;
}

const BASE_STROKE_WIDTH = 1;
const ACTIVE_STROKE_WIDTH = 8;

const Location: React.FC<IProps> = ({ location, onClick, active }) => {
  const { colors } = useTheme();
  const { teams, layout } = useMapContext();

  if (location.x == null || location.y == null) return null;
  const position = { x: location.x, y: location.y };

  const coordinates = layout.getPositionCoordinates(position);

  return (
    <Hex
      key={location.id}
      coordinates={coordinates}
      label={location.type === 'BattleLocation' ? `${location.x},${location.y}` : location.name}
      labelProps={{ fill: colors.locationText, fontSize: 24, fontWeight: 'bold' }}
      onClick={onClick}
      animate={{
        fill: location.obscured
          ? colors.locationBackgroundObscured
          : teams[location.team_id]?.color ?? colors.locationBackground,
        stroke: active ? colors.locationBorderActive : colors.locationBorder,
        strokeWidth: active ? ACTIVE_STROKE_WIDTH : BASE_STROKE_WIDTH,
      }}
      transition={{ duration: 0.15, type: 'tween' }}
      whileHover={{
        stroke: colors.locationBorderHover,
        strokeWidth: ACTIVE_STROKE_WIDTH,
        transition: { duration: 0.15, type: 'tween' },
      }}
      style={{ cursor: onClick != null ? 'pointer' : undefined }}
    />
  );
};

export default Location;
