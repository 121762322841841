import isPropValid from '@emotion/is-prop-valid';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import theme from 'lib/theme';
import * as React from 'react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

const queryClient = new QueryClient();

interface IProps {
  children?: React.ReactNode;
}

const App: React.FC<IProps> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <StyleSheetManager shouldForwardProp={isPropValid}>{children}</StyleSheetManager>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
