import { IPosition, useMapContext } from 'components/MapContextProvider';
import CircleMarker from 'components/atoms/CircleMarker';
import HexProgress from 'components/atoms/HexProgress';
import HexSegment from 'components/atoms/HexSegment';
import PlayerMarker from 'components/atoms/PlayerMarker';
import { ILocation, TMarker, isCircleMarker, isPlayerMarker, isProgressMarker, isSegmentMarker } from 'lib/api';
import { THexSegment } from 'lib/hex';
import * as React from 'react';
import { useTheme } from 'styled-components';

interface IProps {
  locations: ILocation[];
  markers: TMarker[];
}

interface ILocationLookup {
  [key: string]: {
    location: ILocation;
    locationOrbits: {
      [key: number]: TMarker[];
    };
    position: IPosition;
  };
}

const DEFAULT_MARKER_ORBIT = 0.25;
const PLAYER_MARKER_ORBIT = 0.5;

const markerOrder: THexSegment[] = ['topRight', 'right', 'bottomRight', 'bottomLeft', 'left', 'topLeft'];

const Markers: React.FC<IProps> = ({ locations, markers }) => {
  const { colors } = useTheme();
  const { layout } = useMapContext();

  const circleMarkers = React.useMemo(() => markers.filter(isCircleMarker), [markers]);
  const progressMarkers = React.useMemo(() => markers.filter(isProgressMarker), [markers]);
  const segmentMarkers = React.useMemo(() => markers.filter(isSegmentMarker), [markers]);
  const playerMarkers = React.useMemo(() => markers.filter(isPlayerMarker), [markers]);

  const locationsLookup = React.useMemo(() => {
    return locations.reduce<ILocationLookup>((lookup, location) => {
      if (location.x == null || location.y == null) return lookup;
      const locationItems = [...circleMarkers, ...playerMarkers].filter(marker => marker.placement_id === location.id);
      const locationOrbits = locationItems.reduce<{ [key: number]: TMarker[] }>((orbits, marker) => {
        const orbit = isPlayerMarker(marker) ? PLAYER_MARKER_ORBIT : marker.marker_props?.orbit ?? DEFAULT_MARKER_ORBIT;
        if (orbits[orbit] == null) orbits[orbit] = [];
        orbits[orbit].push(marker);
        return orbits;
      }, {});
      lookup[location.id] = { location, locationOrbits, position: { x: location.x, y: location.y } };
      return lookup;
    }, {});
  }, [locations, circleMarkers]);

  return (
    <>
      {locations.map(location => {
        const { position } = locationsLookup[location.id];
        const coordinates = layout.getPositionCoordinates(position);

        return (
          <React.Fragment key={location.id}>
            {markerOrder.map((segmentKey, i) => {
              const marker = segmentMarkers.find(
                marker => marker.placement_id === location.id && marker.segment === segmentKey
              );
              return (
                <HexSegment
                  key={segmentKey}
                  coordinates={coordinates}
                  segmentKey={segmentKey}
                  active={marker != null}
                  fill={marker?.color ?? colors.segmentBackground}
                />
              );
            })}
          </React.Fragment>
        );
      })}
      {circleMarkers.map(marker => {
        const { position, locationOrbits } = locationsLookup[marker.placement_id];
        const orbit = marker.marker_props?.orbit ?? DEFAULT_MARKER_ORBIT;
        const orbitItems = locationOrbits[orbit] ?? [];
        const index = orbitItems.indexOf(marker);
        const coordinates = layout.getCoordinatesForMarker(position, orbit, orbitItems.length, index);

        return (
          <CircleMarker key={marker.id} coordinates={coordinates} marker={marker} style={{ pointerEvents: 'none' }} />
        );
      })}
      {playerMarkers.map(marker => {
        const location = locationsLookup[marker.placement_id];
        if (!location) return null; // player can be on route
        const { position, locationOrbits } = location;
        const orbit = PLAYER_MARKER_ORBIT;
        const orbitItems = locationOrbits[orbit] ?? [];
        const index = orbitItems.indexOf(marker);
        const coordinates = layout.getCoordinatesForMarker(position, orbit, orbitItems.length, index);

        return (
          <PlayerMarker key={marker.id} coordinates={coordinates} marker={marker} style={{ pointerEvents: 'none' }} />
        );
      })}
      {progressMarkers.map(marker => (
        <HexProgress
          key={marker.id}
          coordinates={layout.getPositionCoordinates(locationsLookup[marker.placement_id].position)}
          progress={marker.progress}
          stroke={marker.color}
        />
      ))}
    </>
  );
};

export default Markers;
