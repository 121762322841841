import TeamScore from 'components/molecules/TeamScore';
import { ITeam } from 'lib/api';
import * as React from 'react';

interface IProps {
  teams: ITeam[];
}

const ScoreTracker: React.FC<IProps> = ({ teams }) => {
  return (
    <React.Fragment>
      {teams
        .filter(t => !t.hidden)
        .map((t, i) => (
          <TeamScore key={t.id} team={t} side={i === 0 ? 'right' : 'left'} />
        ))}
    </React.Fragment>
  );
};

export default ScoreTracker;
