import App from 'components/App';
import MapPage from 'components/pages/MapPage';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

const container = document.querySelector('div#map');
if (container != null) {
  const root = createRoot(container!);
  root.render(
    <StrictMode>
      <App>
        <MapPage dataSourceUrl={container?.getAttribute('data-map-data-source-url-value') ?? ''} />
      </App>
    </StrictMode>
  );
}
